<template>
    <!-- 签到打卡 ————> 查看 -->
    <div class="box-card" style="text-align:left;">
      <div class="sign-content" style="text-align: left;">
        <div class="title">人员信息</div>
        <el-divider></el-divider>
        <el-form label-position="right" label-width="180px" :model="detailsList">
          <el-form-item label="姓名：">{{detailsList.inspector_name}}</el-form-item>
          <el-form-item label="手机号：">{{detailsList.phone}}</el-form-item>
          <el-form-item label="考勤组：">{{detailsList.attendance_group_name}}</el-form-item>
        </el-form>
        <div class="title" >打卡详情</div>
        <el-divider></el-divider>
        <el-form label-position="right" label-width="180px" :model="detailsList">
          <el-form-item label="打卡时间：">
            {{ $moment(detailsList.clock_in_time).format("YYYY-MM-DD HH:mm:ss") }}
          </el-form-item>
          <el-form-item label="打卡类型：">
            {{ getWayName("ClockType", detailsList.clock_type) }}
          </el-form-item>
          <!-- 如果是人脸打卡显示人脸打卡机名称 -->
          <el-form-item label="识别设备：" v-if="detailsList.clock_type===1">
            {{detailsList.device_face_name}}
          </el-form-item>
          <template v-else>
            <el-form-item label="打卡位置：" >{{detailsList.clock_in_address}}</el-form-item>
          <el-form-item>
            <div id="mapwork" class="map"></div>
          </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
  </template>

<script>
import { getCheckDetails } from '@/api/administrative'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      check_in_id: null,
      zoom: 12.8,
      postionMap: {
        lng: 112.887708,
        lat: 28.234755
      },
      jgNameDialog: false,
      show: true,
      detailsList: {}
    }
  },
  created () {
    this.check_in_id = this.id
    // this.$nextTick(() => {
    this.fngetCheckDetails()
    // })
  },
  computed: {
    ...mapGetters(['getWayName'])
  },
  mounted () { },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fngetCheckDetails () {
      const res = await getCheckDetails({
        id: this.check_in_id
      })
      console.log(res)
      this.detailsList = res.Data
      this.postionMap = {
        lng: this.detailsList.clock_in_longitude,
        lat: this.detailsList.clock_in_latitude
      }
      setTimeout(() => { this.initAmap() }, 200)
    },
    initAmap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        var mapwork = new AMap.Map('mapwork', {
          center: new AMap.LngLat(that.postionMap.lng, that.postionMap.lat),
          zoom: 12.8
        })
        mapwork.clearMap()
        var marker = new AMap.Marker({
          map: mapwork,
          position: mapwork.getCenter()
        })
        var divInfo = document.createElement('div')
        divInfo.className = 'divInfo'
        divInfo.innerHTML = '地址：' + that.detailsList.clock_in_address
        var infoWindow = new AMap.InfoWindow({
          isCustom: true, // 使用自定义窗体
          anchor: 'top-center',
          content: divInfo
        })
        infoWindow.open(mapwork, marker.getPosition())
        // 下班地址
        // var mapoff = new AMap.Map("mapworkoff", {
        //   center: new AMap.LngLat(that.off_work_postionMap.lng, that.off_work_postionMap.lat),
        //   zoom: 12.8,
        // });
        // mapoff.clearMap();
        // var offmarke = new AMap.Marker({
        //   map: mapoff,
        //   position: mapoff.getCenter(),
        // });
        // divInfo.innerHTML = "地址：" + that.detailsList.off_work_address
        // var infoWindowoff = new AMap.InfoWindow({
        //   isCustom: true, // 使用自定义窗体
        //   anchor: "top-center",
        //   content: divInfo,
        // });
        // infoWindowoff.open(mapoff, offmarke.getPosition());
      })
    },
    go_to_work_handler ({ Bmap, map }) {
      // console.log(Bmap, map);
      this.postionMap = {
        lng: this.detailsList.clock_in_longitude,
        lat: this.detailsList.clock_in_latitude
      }
    },
    // infoWindowClose和infoWindowOpen是控制信息窗口关闭隐藏的方法
    infoWindowClose () {
      this.show = false
    },
    // infoWindowClose和infoWindowOpen是控制信息窗口关闭隐藏的方法
    infoWindowOpen () {
      setInterval(() => {
        this.show = true
      }, 100)
    }
  }
}
</script>

  <style scoped lang="less">
  .title {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .sign-content .el-form-item {
    margin-bottom: 0px;
  }
  .map {
    width: 95%;
    height: 350px;
    /deep/.amap-overlays {
      .amap-info-contentContainer {
        background: #ffebcd;
        padding: 0;
        .divInfo {
          padding: 10px;
          line-height: initial;
        }
      }
    }
  }
  // .map {
  //   width: 800px;
  //   height: 500px;
  //   /deep/.amap-maps {
  //     .amap-drags {
  //       .amap-overlays {
  //         /deep/ .amap-info {
  //           .amap-info-contentContainer {
  //             /deep/ .amap-info-content {
  //               background: red;
  //               .divInfo {
  //                 line-height: 25px;
  //               }
  //             }
  //             .bottom-center .amap-info-sharp {
  //               bottom: 0;
  //               left: 50%;
  //               margin-left: -8px;
  //               border-left: 8px solid transparent;
  //               border-right: 8px solid transparent;
  //               border-top: 8px solid #ffebcd;
  //             }
  //             .amap-info-close {
  //               position: absolute;
  //               top: 5px;
  //               right: 5px !important;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  </style>
