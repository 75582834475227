<template>
  <!-- 考勤打卡 —— 打卡记录 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef">
      <el-form-item label="姓名：">
        <el-input v-model="queryForm.inspector_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.inspector_name = validSpace(e))" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="queryForm.phone" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.phone = validSpace(e))" placeholder="请输入手机号" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item>
        <el-form-item label="打卡类型：">
          <el-select popper-class="my-select"  v-model="queryForm.clock_type" filterable default-first-option clearable
            placeholder="请选打卡类型" @change="onSubmit()">
            <el-option v-for="item in getOptions('ClockType')" :key="item.value" :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="打卡记录">
      <template slot="heard-right">
        <div class="right">
          <!-- <el-button  size="small" type="danger"  @click="batchDel" >删除</lbButton> -->
          <lbButton type="warning" icon="daochu" @click="exportFile" v-preventReClick
            v-if="isShowBtn('915ea286e40c44cfb5e8bd66')">导出</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
          @current-change="currentChange">
          <template slot-scope="{row}" slot="clock_in_time">
            {{ validDateTime(row.clock_in_time) }}
          </template>
           <template slot-scope="{row}" slot="clock_type">
            {{ getWayName("ClockType", row.clock_type)  }}
          </template>
            <template slot-scope="{row}" slot="clock_in_address">
            {{ row.clock_type===1?row.device_face_name:row.clock_in_address }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="openDetails(row)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="inspector_name" label="姓名" width="200"></el-table-column>
          <el-table-column prop="phone" label="手机号" width="220"></el-table-column>
          <el-table-column prop="attendance_group_name" label="考勤组"></el-table-column>
          <el-table-column label="打卡时间" width="140">
            <template slot-scope="scope">
              {{ validDateTime(scope.row.clock_in_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="clock_type" label="打卡类型" >
            <template slot-scope="scope">
              {{ getWayName("ClockType", scope.row.clock_type)}}
            </template>
          </el-table-column>
          <el-table-column prop="clock_in_address" label="打卡位置" >
            <template slot-scope="scope">
              {{ scope.row.clock_type === 1? scope.row.device_face_name :scope.row.clock_in_address }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="openDetails(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 查看打卡记录 -->
    <lebo-dialog title="查看打卡记录" width="60%" :isShow="showLookSignDetails" @close="showLookSignDetails = false" footerSlot closeOnClickModal>
      <lookSignDetails v-if="showLookSignDetails" :id="dialogSignDetailsId"></lookSignDetails>
    </lebo-dialog>
  </div>
</template>

<script>
import { getInspectorCheckList } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import lookSignDetails from './components/lookSignDetails/index.vue'
// import FileSaver from "file-saver";
// import XLSX from "xlsx";
export default {
  components: { lookSignDetails },
  data () {
    return {
      // 查询表单
      queryForm: {
        inspector_name: '',
        phone: '',
        clock_type: null, // 打卡类型
        PageIndex: 1,
        PageSize: 10
      },
      total: 0,
      // 表格数据
      tableData: [],
      dialogSignDetailsId: '',
      showLookSignDetails: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '姓名',
            prop: 'inspector_name'
          }, {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '考勤组',
            prop: 'attendance_group_name'
          }, {
            label: '打卡时间',
            prop: 'clock_in_time',
            slot: true
          }, {
            label: '打卡类型',
            prop: 'clock_type',
            slot: true
          }, {
            label: '打卡位置',
            prop: 'clock_in_address',
            width: 350,
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      console.log(this.queryForm)
    }
    this.fngetInspectorCheckList()
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取打卡记录
    async fngetInspectorCheckList () {
      const res = await getInspectorCheckList(
        {
          PageSize: this.queryForm.PageSize,
          PageIndex: this.queryForm.PageIndex,
          inspector_name: this.queryForm.inspector_name,
          phone: this.queryForm.phone,
          clock_type: this.queryForm.clock_type ? this.queryForm.clock_type : 0 // 打卡类型
        }
      )
      // console.log(res.Data.DataList);
      if (res && res.Code === 200) {
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
      } else {
        this.total = 0
        this.tableData = []
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      // console.log(this.tableData);
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetInspectorCheckList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetInspectorCheckList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetInspectorCheckList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      // console.log(`当前页: ${val}`);
      this.fngetInspectorCheckList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetInspectorCheckList()
    // },
    // 导出
    async exportFile () {
      const res = await getInspectorCheckList({
        inspector_name: this.queryForm.inspector_name,
        phone: this.queryForm.phone,
        clock_type: this.queryForm.clock_type,
        PageIndex: 0,
        PageSize: 0
      })
      // console.log(res.Data.DataList);
      const resultList = res.Data.DataList
      if (resultList.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '姓名',
            '手机号',
            '考勤组',
            '打卡时间',
            '打卡位置'
          ]
          const filterVal = [
            'inspector_name',
            'phone',
            'attendance_group_name',
            'clock_in_time',
            'clock_in_address'
          ]
          const list = resultList
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '打卡记录' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        var map = {}
        this.$set(map, j, v[j])
        return this.formatter(map, { property: j })
        // 为formatter专门为了码表渲染的组件
      })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // console.log(row);
      // console.log(column);
      // 日期处理
      if (column.property === 'clock_in_time') {
        return row[column.property] ? this.$moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss') : '/'
      } else {
        return row[column.property]
      }
    },
    // 打开详情页面
    openDetails (row) {
      this.dialogSignDetailsId = row._id
      this.showLookSignDetails = true
    }
  }
}
</script>

<style scoped lang="less"></style>
